import { ref } from '@vue/composition-api'
import store from '@/store'

export const useOrderPayInUi = () => {
  const itemView = ref('pay-in')
  const itemViewOptions = ref([
    { icon: 'EyeIcon', value: 'pay-in', text: 'Pay In' },
    { icon: 'EyeOffIcon', value: 'pay-out', text: 'Pay Out' },
  ])

  return {
    itemView,
    itemViewOptions,
  }
}

export const useOrderPayOutUi = () => {
  const itemView = ref('pay-out')
  const itemViewOptions = ref([
    { icon: 'EyeOffIcon', value: 'pay-in', text: 'Pay In' },
    { icon: 'EyeIcon', value: 'pay-out', text: 'Pay Out' },
  ])

  return {
    itemView,
    itemViewOptions,
  }
}

export const useMasterFilter = () => {
  const merchantName = ref('')
  const merchantNameOptions = ref([])
  const dateFrom = ref('')
  const dateTo = ref('')
  const payGate = ref('')
  const payGateOptions = ref([])
  const orderStatus = ref('')
  const orderStatusOptions = ref([
    { value: 'pending', text: 'Đang xử lý' },
    { value: 'success', text: 'Hoàn thành' },
    { value: 'fail', text: 'Thất bại' },
    { value: 'wrong_amount', text: 'Sai mệnh giá' },
  ])
  const statistic = ref({
    totalMoneyTrade: 0, totalMoneyTradeSuccess: 0, totalMoneyTradeFail: 0, totalCountTrade: 0,
  })
  const fetchStatistic = async (...args) => store.dispatch('order-management/fetchStatistic', ...args)

  const fetchMerchantNameOptions = async () => {
    merchantNameOptions.value = await store.dispatch('order-management/fetchMerchantMaster')
  }

  const fetchPayGateOptions = async () => {
    payGateOptions.value = await store.dispatch('order-management/fetchPayGateMaster')
  }

  return {
    merchantName,
    merchantNameOptions,
    dateFrom,
    dateTo,
    payGate,
    payGateOptions,
    orderStatus,
    orderStatusOptions,
    statistic,

    fetchMerchantNameOptions,
    fetchPayGateOptions,
    fetchStatistic,
  }
}

export const useOderPayInFilter = () => {
  // table
  const pageLength = ref(10)
  const fields = ref([
    '#',
    {
      key: 'transactionId',
      label: 'mã giao dịch',
    },
    {
      key: 'orderId',
      label: 'mã đơn hàng',
    },
    {
      key: 'payGateName',
      label: 'cổng',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    { key: 'merchantName', label: 'merchant' },
    {
      key: 'receiver',
      label: 'nơi nhận',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    {
      key: 'detail.content',
      label: 'nội dung',
      thClass: 'text-center',
      tdClass: 'text-center',
    },
    { key: 'detail.inputAmount', label: 'số tiền nhập' },
    { key: 'detail.realAmount', label: 'số tiền nhận' },
    { key: 'detail.forControl', label: 'đối soát' },
    { key: 'fee', label: 'phí' },
    { key: 'detail.realReceiveAmount', label: 'thực nhận' },
    { key: 'createdAt', label: 'ngày nhận' },
    { key: 'updatedAt', label: 'ngày xử lý' },
    { key: 'status', label: 'tình trạng' },
    { key: 'actions', label: 'hành động' },
  ])
  const items = ref([])
  const pagination = ref({
    total: 1, count: 1, per_page: 15, current_page: 1, total_pages: 1,
  })
  const searchKeyWord = ref('')

  const handleSearch = (...args) => store.dispatch('order-management/fetchPayin', ...args)

  const mapStatus = status => {
    switch (status) {
      case 'pending':
        return 'Chờ xử lý'
      case 'success':
        return 'Thành công'
      case 'fail':
        return 'Thất bại'
      case 'wrong_amount':
        return 'Sai mệnh giá'
      default:
        return 'Chờ xử lý'
    }
  }

  const mapVariant = status => {
    switch (status) {
      case 'pending':
        return 'warning'
      case 'success':
        return 'success'
      case 'fail':
        return 'danger'
      case 'wrong_amount':
        return 'warning'
      default:
        return 'warning'
    }
  }

  return {
    pageLength,
    fields,
    items,
    pagination,
    searchKeyWord,

    handleSearch,
    mapStatus,
    mapVariant,
  }
}

export const useUsdtFilter = () => {
  // table
  const pageLength = ref(10)
  const fields = ref([
    '#',
    {
      key: 'transactionId',
      label: 'mã giao dịch',
    },
    {
      key: 'merchantName',
      label: 'merchant',
    },
    { key: 'amountBeforeChange', label: 'số dư đầu' },
    { key: 'amountChange', label: 'biến động' },
    { key: 'amountAfterChange', label: 'Số dư cuối' },
    { key: 'createdAt', label: 'ngày nhận' },
    { key: 'updatedAt', label: 'ngày xử lý' },
    { key: 'status', label: 'tình trạng' },
  ])
  const items = ref([])
  const pagination = ref({
    total: 1, count: 1, per_page: 15, current_page: 1, total_pages: 1,
  })
  const searchKeyWord = ref('')

  const handleSearch = (...args) => store.dispatch('order-management/fetchPayin', ...args)

  const mapStatus = status => {
    switch (status) {
      case 'pending':
        return 'Chờ xử lý'
      case 'success':
        return 'Thành công'
      case 'fail':
        return 'Thất bại'
      case 'wrong_amount':
        return 'Sai mệnh giá'
      default:
        return 'Chờ xử lý'
    }
  }

  const mapVariant = status => {
    switch (status) {
      case 'pending':
        return 'warning'
      case 'success':
        return 'success'
      case 'fail':
        return 'danger'
      case 'wrong_amount':
        return 'warning'
      default:
        return 'warning'
    }
  }

  return {
    pageLength,
    fields,
    items,
    pagination,
    searchKeyWord,

    handleSearch,
    mapStatus,
    mapVariant,
  }
}

export const useOderPayInActions = () => {
  const handleCallbackCustomer = (...args) => store.dispatch('order-management/callbackCustomer', ...args)
  const handleChangeStatus = (...args) => store.dispatch('order-management/callbackCustomer', ...args)

  return {
    handleCallbackCustomer,
    handleChangeStatus,
  }
}

export const useOrderPayoutActions = () => {
  const changeOrderStatus = (...args) => store.dispatch('order-management/changeOrderStatusPayout', ...args)

  return {
    changeOrderStatus,
  }
}

export const useOderPayOutFilter = () => {
  // table
  const fields = ref([
    '#',
    { key: 'transactionId', label: 'mã giao dịch' },
    { key: 'merchantName', label: 'merchant' },
    { key: 'receiver', label: 'ngân hàng nhận' },
    { key: 'detail.receiverNumber', label: 'tài khoản nhận' },
    { key: 'detail.receiverName', label: 'tên người nhận' },
    { key: 'detail.sourceAccountId', label: 'nguồn chuyển' },
    { key: 'detail.amount', label: 'số tiền' },
    { key: 'fee', label: 'phí' },
    { key: 'amountChange', label: 'tổng số tiền' },
    { key: 'createdAt', label: 'ngày nhận' },
    { key: 'updatedAt', label: 'ngày xử lý' },
    { key: 'status', label: 'tình trạng' },
    { key: 'actions', label: 'hành động' },
  ])
  const items = ref([])
  const pagination = ref({
    total: 1, count: 1, per_page: 15, current_page: 1, total_pages: 1,
  })
  const searchKeyWord = ref('')

  const fetchPayouts = (...args) => store.dispatch('order-management/fetchPayout', ...args)

  const mapStatus = status => {
    switch (status) {
      case 'pending':
        return 'Chờ xử lý'
      case 'success':
        return 'Thành công'
      case 'fail':
        return 'Thất bại'
      case 'wrong_amount':
        return 'Sai mệnh giá'
      default:
        return 'Chờ xử lý'
    }
  }

  const mapVariant = status => {
    switch (status) {
      case 'pending':
        return 'warning'
      case 'success':
        return 'success'
      case 'fail':
        return 'danger'
      case 'wrong_amount':
        return 'warning'
      default:
        return 'warning'
    }
  }

  return {
    fields,
    items,
    pagination,
    searchKeyWord,

    fetchPayouts,
    mapStatus,
    mapVariant,
  }
}

export const usePayinHistory = () => {
  const search = ref(null)
  const dateFrom = ref('')
  const dateTo = ref('')
  const orderStatus = ref('')
  const orderStatusOptions = ref([
    { value: 'success', text: 'Thành công' },
    { value: 'fail', text: 'Thất bại' },
    { value: 'timeout', text: 'Ngừng cập nhật' },
  ])
  const mapStatus = status => {
    switch (status) {
      case 'success':
        return 'Thành công'
      case 'fail':
        return 'Thất bại'
      case 'timeout':
        return 'Ngừng cập nhật'
      default:
        return 'Chờ xử lý'
    }
  }

  const fields = ref([
    '#',
    { key: 'chanel', label: 'KÊNH THANH TOÁN' },
    { key: 'bankName', label: 'MÃ GIAO DỊCH' },
    { key: 'bankName', label: 'NƠI NHẬN' },
    { key: 'bankAccountNumber', label: 'SỐ TIỀN NHẬP' },
    { key: 'BankAccountName', label: 'SỐ TIỀN NHẬN' },
    { key: 'content', label: 'ĐỐI SOÁT' },
    { key: 'amount', label: 'THỰC NHẬN' },
    { key: 'createdAt', label: 'THỜI GIAN TẠO' },
    { key: 'updatedAt', label: 'THỜI GIAN CẬP NHẬT' },
    { key: 'status', label: 'Trạng thái' },
  ])
  const items = ref([])

  const meta = ref({
    itemCount: 0,
    page: 1,
    pageCount: 0,
    take: 50,
  })

  const fetchPayinHistory = (...arg) => store.dispatch('order-management/fetchPayin', ...arg)

  return {
    dateFrom,
    dateTo,
    orderStatus,
    orderStatusOptions,
    search,
    meta,
    fields,
    items,
    mapStatus,
    fetchPayinHistory,
  }
}

export const useTransactionHistory = () => {
  const fields = ref([
    '#',
    { key: 'transactionId', stickyColumn: true, label: 'mã giao dịch' },
    { key: 'type', label: 'cổng' },
    { key: 'payGateName', label: 'kênh' },
    { key: 'port', label: 'loại' },
    { key: 'merchantName', label: 'merchant' },
    { key: 'detail.sourceAccountId', label: 'nơi thục hiện' },
    { key: 'user', label: 'người thực hiện' },
    { key: 'amountBeforeChange', label: 'số dư đầu' },
    { key: 'amountChange', label: 'biến động' },
    { key: 'amountAfterChange', label: 'số dư cuối' },
    { key: 'updatedAt', label: 'ngày cập nhật' },
  ])
  const items = ref([])
  const pagination = ref({
    total: 1, count: 1, per_page: 15, current_page: 1, total_pages: 1,
  })

  const fetchBalanceHistory = (...args) => store.dispatch('order-management/fetchBalanceHistory', ...args)

  return {
    fields,
    items,
    pagination,
    fetchBalanceHistory,
  }
}
