<template>
  <div>
    <b-row>
      <b-col>
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="search"
            placeholder="Mã giao dịch/ Nội dung"
          />
        </b-input-group>
      </b-col>

      <b-col cols="2">
        <b-form-datepicker
          v-model="dateFrom"
          local="vn"
          placeholder="Từ ngày"
          selected-variant="primary"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </b-col>

      <b-col cols="2">
        <b-form-datepicker
          v-model="dateTo"
          local="vn"
          placeholder="Đến ngày"
          selected-variant="primary"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </b-col>

      <b-col cols="2">
        <b-form-group>
          <v-select
            v-model="orderStatus"
            placeholder="Trạng thái"
            label="text"
            :options="orderStatusOptions"
            :reduce="status => status.value"
          />
        </b-form-group>
      </b-col>

      <b-col cols="auto">
        <b-button
          variant="primary"
          class="mr-1"
          @click="fetchBankAccount"
        >
          Tìm kiếm
        </b-button>
      </b-col>
    </b-row>

    <b-table
      small
      :fields="fields"
      :items="items"
      responsive="sm"
      class="mt-2"
      show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>

      <template #empty="scope">
        <h4 class="text-center">
          không có dữ liệu
        </h4>
      </template>

      <template #cell(status)="data">
        <span class="text-nowrap">{{ mapStatus(data.value) }}</span>
      </template>

      <template #cell(createdAt)="data">
        <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
      </template>

      <template #cell(updatedAt)="data">
        <span class="text-nowrap">{{ formatDateTimeDb(data.value) }}</span>
      </template>

      <template #cell(moneyLimitPerDay)="data">
        <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
      </template>

      <template #cell(moneyLimitToParent)="data">
        <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
      </template>

      <template #cell(totalMoneyIn)="data">
        <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
      </template>

      <template #cell(balancer)="data">
        <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
      </template>

      <template #cell(moneyReceive)="data">
        <span class="text-nowrap">{{ data.value.toLocaleString() }}</span>
      </template>
    </b-table>

    <b-row>
      <b-col class="d-flex justify-content-md-end">
        <b-pagination
          v-model="meta.page"
          :total-rows="meta.itemCount"
          :per-page="meta.take"
          first-text="Trang đầu"
          prev-text="Trang trước"
          next-text="Trang tiếp"
          last-text="Trang cuối"
          align="left"
          @change="changePage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import { formatDateTimeDb } from '@/libs/timezone'
import vSelect from 'vue-select'
import { usePayinHistory } from './useOrder'

export default {
  name: 'PayinHistory',
  components: {
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormGroup,
    BFormDatepicker,
    vSelect,
  },
  setup() {
    const {
      search,
      dateFrom,
      dateTo,
      orderStatus,
      orderStatusOptions,
      fetchBankAccount,
      meta,
      fields,
      items,
      mapStatus,
      fetchPayinHistory,
    } = usePayinHistory()

    const fetchData = () => {
      fetchPayinHistory().then(res => {
      }).catch(err => {
        // console.log(err)
      })
    }

    const changePage = page => {
      meta.value.page = page
      fetchData()
    }

    return {
      search,
      dateFrom,
      dateTo,
      orderStatus,
      orderStatusOptions,
      fetchBankAccount,
      meta,
      fields,
      items,

      formatDateTimeDb,
      mapStatus,
      changePage,
    }
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
